;(function($) {
	
	$(document).ready(function() {

		$('.homepage-hero__carousel').on('lazyLoaded', function(e, slick, image, imageSource) {

			$(image).parent('.homepage-hero__carousel__image').css({
				'background-image': 'url(' + imageSource + ')'
			});
		});

		$('.homepage-hero__carousel').slick({
			autoplay: true,
			speed: 1000,
			autoplaySpeed: 5000,
			slidesToShow: 1,
			pauseOnFocus: false,
			pauseOnHover: false,
			arrows: false,
			dots: false,
			fade: true,
			lazyLoad: 'ondemand'
		});

		$('.js-testimonials').slick({
			autoplay: true,
			speed: 1000,
			autoplaySpeed: 5000,
			slidesToShow: 1,
			pauseOnFocus: false,
			pauseOnHover: false,
			arrows: false,
			dots: false,
		});

		var update_home_info = function(index) {
			var home_info = $('.js-homepage-homes [data-slick-index="' + index + '"]').attr('data-slide-info');
			home_info = JSON.parse(home_info);
			$('[data-home-carousel-link]').attr('href', home_info.permalink);
			$('[data-home-carousel-title]').css({
				opacity: 0
			});
			setTimeout(function() {
				$('[data-home-carousel-title]').text(home_info.title).css({
					opacity: 1
				});
			}, 500);
			
		}

		var js_homepage_homes = $('.js-homepage-homes');

		js_homepage_homes.on('init', function() {
			update_home_info(0);
		});

		js_homepage_homes.slick({
			autoplay: true,
			speed: 1000,
			autoplaySpeed: 5000,
			slidesToShow: 1,
			pauseOnFocus: false,
			pauseOnHover: false,
			arrows: false,
			dots: true,
			fade: true,
			dotsClass: 'homes-carousel-dots',
			appendDots: $('.homepage-homes__carousel__dots'),
		});

		js_homepage_homes.on('beforeChange', function(event, slick, currentSlide, nextSlide) {
			update_home_info(nextSlide);
		});

		js_homepage_homes.on('lazyLoaded', function(e, slick, image, imageSource) {

			$(image).parent('.homepage-homes__carousel__background').css({
				'background-image': 'url(' + imageSource + ')'
			});
		});

		$('.js-main-carousel').slick({
			autoplay: false,
			speed: 1000,
			autoplaySpeed: 5000,
			slidesToShow: 1,
			pauseOnFocus: false,
			pauseOnHover: false,
			arrows: false,
			dots: false,
			fade: true,
			asNavFor: '.js-nav-carousel'
		});

		console.log($('.single-homes__main__carousel__item').length);

		$('.js-nav-carousel').slick({
			slidesToShow: 1,
			slidesToScroll: 1,
			asNavFor: '.js-main-carousel',
			arrows: true,
			dots: false,
			centerMode: true,
			focusOnSelect: true,
			mobileFirst: true,
			responsive: [
				{
					breakpoint: 600,
					settings: {
						slidesToShow: 2,
					}
				},
				{
					breakpoint: 1100,
					settings: {
						slidesToShow: 5,
						centerMode: ($('.single-homes__main__carousel__item').length > 6) ? true : false,
					}
				}
			]
		});

	});

})(jQuery);