;(function($) {

	$(document).ready(function() {
		$('html').removeClass('no-js').addClass('js');
	});

	$(document).ready(function() {
		var inviewOffset = (isMobile() === true) ? 0 : 100;
		inView.offset(inviewOffset); 
		inView('.element-inview').on('enter', function(event, handler) {
			var element = $(event);
			element.addClass('element-inview-animated');
		});
	});

	var isMobile = function() {
		return ($('html').hasClass('app_touchevents')) ? true : false;
	}

	var initSkrollr = function() {
		if (!isMobile()) {
			var s = skrollr.init({
				forceHeight: false,
				smoothScrolling: true,
				smoothScrollingDuration: 200
			});
		} else {
			$('html').addClass('no-skrollr');
		}
	}

	$(document).ready(initSkrollr);

	var openVideo = function() {
		$('.video-overlay__action').css({
			'display': 'none'
		});

		$('.next-open-house').css({
			'opacity': 0,
			'visibility': 'hidden'
		});

		$('.video-overlay__video').css({
			'display': 'block',
			'opacity': 0
		});

		var video = $('[data-video]').attr('data-video');
		$('.video-overlay__container').html(JSON.parse(video));

		setTimeout(function() {
			$('.video-overlay__video').css({
				'opacity': 1
			});
		}, 500);
	}

	var closeVideo = function() {
		$('.video-overlay__container').html(null);

		$('.video-overlay__action').removeAttr('style');
		$('.video-overlay__video').removeAttr('style');
		$('.next-open-house').removeAttr('style');
	}

	$('.video-overlay__action').click(function() {
		openVideo();
	});

	$('.video-overlay__close').click(function() {
		closeVideo();
	})

})(jQuery);