(function($) {

	var custom_map_style = [
		{
			"featureType": "all",
			"elementType": "labels.text.fill",
			"stylers": [
				{
					"color": "#24324c"
				}
			]
		},
		{
			"featureType": "administrative",
			"elementType": "all",
			"stylers": [
				{
					"visibility": "on"
				},
				{
					"lightness": 33
				}
			]
		},
		{
			"featureType": "landscape",
			"elementType": "all",
			"stylers": [
				{
					"color": "#efefef"
				}
			]
		},
		{
			"featureType": "landscape",
			"elementType": "labels.text.fill",
			"stylers": [
				{
					"color": "#24324c"
				}
			]
		},
		{
			"featureType": "poi.park",
			"elementType": "geometry",
			"stylers": [
				{
					"color": "#e3eed3"
				}
			]
		},
		{
			"featureType": "poi.park",
			"elementType": "labels",
			"stylers": [
				{
					"visibility": "on"
				},
				{
					"lightness": 20
				}
			]
		},
		{
			"featureType": "road",
			"elementType": "all",
			"stylers": [
				{
					"lightness": 20
				}
			]
		},
		{
			"featureType": "road",
			"elementType": "labels.text.fill",
			"stylers": [
				{
					"color": "#a93124"
				}
			]
		},
		{
			"featureType": "road.highway",
			"elementType": "geometry",
			"stylers": [
				{
					"color": "#83a5b0"
				}
			]
		},
		{
			"featureType": "road.arterial",
			"elementType": "geometry",
			"stylers": [
				{
					"color": "#bdcdd3"
				}
			]
		},
		{
			"featureType": "road.local",
			"elementType": "geometry",
			"stylers": [
				{
					"color": "#ffffff"
				}
			]
		},
		{
			"featureType": "water",
			"elementType": "all",
			"stylers": [
				{
					"visibility": "on"
				},
				{
					"color": "#b5cbe4"
				}
			]
		}
	];

	var contact_page_map = function() {
		var container = $('.contact-page-body__map');

		if (container.length > 0) {

			var mapData = container.attr('data-google-map');
			mapData = JSON.parse(mapData);

			var googleapi = 'https://maps.googleapis.com/maps/api/js?key=' + theme.googleapi;

			$.getScript(googleapi, function(script, textStatus) {
				
				var latLng = {
					lat: parseFloat(mapData.lat), 
					lng: parseFloat(mapData.lng)
				};

				var map = new google.maps.Map(container[0], {
					scrollwheel: false,
					zoom: 16,
					center: latLng,
					styles: custom_map_style
				});

				var bounds = new google.maps.LatLngBounds();

				var marker = new google.maps.Marker({
					position: latLng,
					map: map,
					icon: theme.logo_map_marker,
					animation: google.maps.Animation.DROP,
				});
			});
		}
	}

	$(document).ready(contact_page_map);

	var single_home_map = function() {
		var container = $('.single-homes__map');

		if (container.length > 0) {

			var mapData = container.attr('data-google-map');
			mapData = JSON.parse(mapData);

			var googleapi = 'https://maps.googleapis.com/maps/api/js?key=' + theme.googleapi;

			$.getScript(googleapi, function(script, textStatus) {
				
				var latLng = {
					lat: parseFloat(mapData.lat), 
					lng: parseFloat(mapData.lng)
				};

				var map = new google.maps.Map(container[0], {
					scrollwheel: false,
					zoom: 16,
					center: latLng,
					styles: custom_map_style
				});

				var bounds = new google.maps.LatLngBounds();

				var icon = {
					url: theme.map_marker,
					size: new google.maps.Size(36, 48),
					scaledSize: new google.maps.Size(36, 48)
				};

				var marker = new google.maps.Marker({
					position: latLng,
					map: map,
					icon: icon,
					size: new google.maps.Size(36, 48),
				});
			});
		}
	}

	$(document).ready(single_home_map);

	var homes_archive_map = function() {
		var container = $('.homes-archive-body__map');

		if (container.length > 0) {

			var googleapi = 'https://maps.googleapis.com/maps/api/js?key=' + theme.googleapi;

			$.getScript(googleapi, function(script, textStatus) {

				var bounds = new google.maps.LatLngBounds();
				var infowindow = new google.maps.InfoWindow();   

				var map = new google.maps.Map(container[0], {
					scrollwheel: false,
					zoom: 16,
					center: {
						lat: 0,
						lng: 0
					},
					styles: custom_map_style
				});

				var icon = {
					url: theme.map_marker,
					size: new google.maps.Size(36, 48),
					scaledSize: new google.maps.Size(36, 48)
				};

				var markers = JSON.parse($('[data-map-markers]').attr('data-map-markers'));
				var marker_data, marker, position;

				console.log('markers', markers);

				for (var i = 0; i < markers.length; i++) {
					marker_data = markers[i].data;

					marker = new google.maps.Marker({
						position: new google.maps.LatLng(parseFloat(marker_data.lat), parseFloat(marker_data.lng)),
						map: map,
						icon: icon,
						size: new google.maps.Size(36, 48),
						animation: google.maps.Animation.DROP,
					});

					bounds.extend(marker.position);

					var openWindow = (function(marker, i) {
						return function() {
							infowindow.setContent(markerContent(i));
							infowindow.open(map, marker);
						}
					})(marker, i);

					var markerContent = function(i) {
						return '<div class="app-map-info-box"> \
									<img src="' + markers[i].thumbnail + '" style="width: 125px; margin: 0 15px 15px 0; float: left; "> \
									<h4>' + markers[i].title + '</h4> \
									<p> \
										<span>' + markers[i].price + '</span> \
										<span>' + markers[i].beds + ' Beds</span> \
										<span>' + markers[i].baths + ' Baths</span> \
									</p> \
									<p><a href="' + markers[i].url + '">View More Details &raquo;</a></p> \
								</div>';
					}

					google.maps.event.addListener(marker, 'click', openWindow);

					jQuery('[data-marker-index-button="' + i + '"] a[href="#locationMap"]').click(openWindow);

					google.maps.event.addListener(map, 'click', function(event) {
						if (infowindow) {
							infowindow.close();
						}
					}); 

					// google.maps.event.addListener(infowindow, 'domready', function() {
					// 	jQuery('.gm-style-iw').parent().addClass('gm-style-iw-container');
					// });
				}

				map.fitBounds(bounds);
			});
		}
	}

	$(document).ready(homes_archive_map);

})(jQuery);